
.app-component_camera {
  position: absolute;

  ::v-deep {

    canvas,
    video {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: transparent;
    }

    .camera__mode {

      video,
      canvas {
        transform: scaleX(-1);
      }
    }
  }
}
