.app-page_live {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .app-component {
    position: absolute;
  }
  .touch-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .record-light {
    position: absolute;
    bottom: 0.3rem;
    right: 0.3rem;
    background: green;
    width: 0.3rem;
    height: 2px;
    border-radius: 2px;
  }
  .progress-pannel {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 5rem;
    left: 50%;
    margin-left: -2.5rem;
    background-color: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 0.2rem;
    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.26rem;
      font-weight: bolder;
      color: #919191;
      &.__state_1 {
        color: #198afa;
        align-items: baseline;
        .van-loading {
          margin-left: 0.03rem;
          margin-right: 0.15rem;
        }
      }
      &.__state_2 {
        color: #389e0d;
      }
      &.__state_3 {
        color: #cf1322;
      }
      &:nth-child(n + 2) {
        margin-top: 0.15rem;
      }
      .van-loading,
      .van-icon {
        font-size: 0.5rem;
        margin-right: 0.1rem;
      }
    }
    .row.txt {
      font-size: 0.24rem;
      line-height: 1.6;
      font-weight: normal;
      color: #010101;
      &.err {
        color: #cf1322;
      }
    }
  }
  .ctrl-menus {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    .ctrl-btn {
      text-align: center;
      &:nth-child(n + 2) {
        margin-top: 0.3rem;
      }
      &.__actived {
        color: #198afa;
      }
      .van-icon {
        display: block;
        margin: 0 auto;
        font-size: 0.7rem;
        &:before {
          text-shadow: 1px 1px 5px #010101;
        }
      }
      text-shadow: 1px 1px 5px #010101;
      font-size: 0.3rem;
      color: #fff;
    }
  }
  .footer-menus {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    .footer-menus_block {
      font-size: 0.3rem;
      color: #f1f1f1;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.1rem 0.1rem;
      border-radius: 0.1rem;
      &:nth-child(n + 2) {
        margin-top: 0.2rem;
      }
      span {
        width: 1.3rem;
        text-align: left;
      }
      .van-switch {
        margin-left: 0.2rem;
      }
    }
  }
  .audio-setting-pannel {
    padding-bottom: 0.5rem;
    .audio-list {
      // box-sizing: border-box;
      // padding: 0 0.2rem;
      .van-button {
        min-width: 1.3rem;
        margin-right: 0.27rem;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(n + 5) {
          margin-top: 0.27rem;
        }
      }
    }
  }
  .count-down-pannel {
    font-size: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 0.3rem;
    .van-count-down {
      font-size: 0.4rem;
      color: #389e0d;
      width: 1.3rem;
      text-align: center;
    }
  }
  .permission-pannel {
    font-size: 0.3rem;
    box-sizing: border-box;
    border-radius: 0.1rem;
    .van-dialog__content {
      padding: 0.3rem;
      .title {
        font-size: 0.3rem;
        font-weight: bolder;
        color: #198afa;
      }
      .desc {
        font-size: 0.26rem;
        margin-top: 0.2rem;
      }
      img {
        width: 4rem;
        display: block;
        margin: 0 auto;
        margin-top: 0.1rem;
        box-shadow: 0px 0px 4px rgb(0 0 0 / 60%);
        border-radius: 3px;
      }
    }
    .permission-pannel_confirm {
      width: 2rem;
      height: 0.7rem;
      margin: 0 auto;
      display: block;
      margin-top: 0.3rem;
    }
  }
  .download-pannel {
    .van-dialog__content {
      box-sizing: border-box;
      padding: 0.5rem;
      .van-progress {
        margin-top: 0.3rem;
      }
      .download-pannel_title {
        font-size: 0.3rem;
        text-align: center;
      }
      .download-pannel_txt {
        overflow: hidden;
        font-size: 0.26rem;
        margin-top: 0.3rem;
        span {
          font-size: 0.3rem;
          font-weight: bolder;
          color: #1989fa;
          display: inline-block;
          padding: 0 0.1rem;
        }
      }
      .van-progress__portion,
      .van-progress__pivot {
        transition: all 0.3s ease;
      }
    }
  }
}
.violation-dialog {
  .van-dialog__message {
    padding: 0.2rem 0.4rem;
    white-space: normal;
    .violation-dialog_content {
      text-align: left;
      font-size: 0.24rem;
      .violation-dialog_content_title {
        text-align: center;
        font-weight: bolder;
        color: #cf1322;
        margin-top: 0.2rem;
        font-size: 0.38rem;
      }
      p {
        line-height: 1.8;
        color: #919191;
        margin-top: 0.1rem;
        span {
          color: #cf1322;
          margin-left: 0.1rem;
        }
      }
    }
  }
}
