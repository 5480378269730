
.btn-login {
  margin: 0 auto;
  display: block;
}

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #010101;
  font-size: 0.5rem;
  padding-top: 3rem;
  font-weight: bolder;

  .qr-countdown-tip {
    font-weight: normal;
    font-size: 0.24rem;
    margin-top: 0.2rem;
    color: #a9a9a9;
    span {
      display: inline-block;
      padding: 0 0.1rem;
      font-size: 0.3rem;
      color: #198afa;
      width: 1rem;
      text-align: center;
    }
  }

  img {
    display: block;
    width: 5rem;
    height: 5rem;
    margin-top: 0.8rem;
  }
}

.content {
  box-sizing: border-box;
  padding-top: 2rem;

  .title {
    font-size: 0.26rem;
    text-align: center;
  }

  .sub-title {
    font-size: 0.22rem;
    text-align: center;
  }

  .buyin-account-list {
    margin: 0 auto;
    margin-top: 1.4rem;
    font-size: 0.3rem;
    height: 3.7rem;
    width: 5rem;
    overflow-x: hidden;
    overflow-y: scroll;

    .block {
      border-radius: 0.1rem;
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      display: flex;
      padding: 0.2rem;
      box-sizing: border-box;
      position: relative;

      &:nth-child(n + 2) {
        margin-top: 0.3rem;
      }

      &.__selected {
        border: 1px solid #198afa;
        color: #198afa;
      }

      .avatar {
        width: 1rem;
        height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        background: url('./assets/default-profile.png') no-repeat 0 0;
        background-size: cover;
      }

      .block-info {
        margin-left: 0.3rem;

        .nickname {
          font-weight: bolder;
          font-size: inherit;
        }

        .dy-no {
          font-size: 0.24rem;
          margin-top: 0.2rem;
          color: #198afa;
        }
      }

      .van-icon {
        position: absolute;
        top: 0.6rem;
        right: 0.4rem;
      }
    }
  }

  .content-btns {
    display: flex;
    justify-content: center;
    button {
      width: 2rem;
      &:nth-child(n + 2) {
        margin-left: 0.3rem;
      }
    }
    .btn-logout {
      color: #a9a9a9;
      border: 0;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0.3rem;
  left: 0;
  width: 100%;
  font-size: 0.3rem;

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.3rem;
    font-weight: bolder;

    .img {
      width: 1rem;
      height: 1rem;
      background: url('./assets/logo.png') no-repeat 0 0;
      background-size: cover;
    }

    span {
      display: inline-block;
      padding: 0 0.03rem;
      font-size: 0.2rem;
    }
  }

  .device {
    font-size: 0.24rem;
  }
}
