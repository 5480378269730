
.app-component_media {
  position: absolute;
  overflow: hidden;

  ::v-deep {
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 0px;
      height: 0px;
      object-fit: cover;
    }

    canvas {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-sizing: border-box;
    }
  }
}
