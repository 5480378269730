.app-page_playground {
  position: relative;
  font-size: 12px;
  line-height: 1;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden;
  .ctrl-view {
    border: 1px solid #e3e3e3;
    // float: left;
    margin-left: 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    .filter-form {
      display: flex;
      // font-size: 16px;
      .filter-form_row {
        &:nth-child(n + 2) {
          margin-left: 20px;
        }
        display: flex;
        align-items: center;
        .txt {
          // font-size: 16px;
          text-align: right;
        }
        .input-field {
          width: 100px;
          margin-left: 10px;
          select {
            display: block;
            width: 100%;
            // font-size: 16px;
          }
        }
      }
      .filter-form_row__btns {
        button {
          width: 100px;
          height: 20px;
          font-size: 14px;
          &:nth-child(n + 2) {
            margin-left: 10px;
          }
        }
      }
    }
    .assets {
      margin-top: 20px;
      // .asset-category {
      // }
      .sub-filter_wrap {
        .sub-filter {
          .sub-filter_label {
            width: 80px;
            text-align: right;
            display: inline-block;
          }
          &:nth-child(n + 2) {
            margin-top: 10px;
          }
        }
      }
      button {
        font-size: 14px;
        background: transparent;
        border: none;
        cursor: pointer;
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
        &.__selected {
          // font-size: 16px;
          color: #e72300;
          background: #e3e3e3;
          text-decoration: underline;
          // padding: 3px 6px;
        }
      }
    }
    .float-form {
      position: absolute;
      right: 10px;
      bottom: 10px;
      button {
        display: inline-block;
        padding: 5px 10px;
      }
    }
  }
  .render-view {
    margin-top: 10px;
    display: flex;
    height: 800px;
    .iframe-wrap {
      border: 1px dashed #333;
      width: 360px;
      height: 800px;
      flex: 0 0 auto;
      position: relative;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .debug-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('https://xiaozhumao-static.oss-cn-shanghai.aliyuncs.com/upload/V2_e82ba66c06.png') no-repeat
          center;
        background-size: auto 100%;
      }
    }
    .logs {
      flex: 1 1 auto;
      margin-left: 20px;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #e9e9e9;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #a1a1a1;
        border-radius: 3px;
      }
      p.row {
        color: #e72300;
        font-size: 12px;
        line-height: 1.4;
        word-break: break-all;
        user-select: text;
        &:nth-child(n + 2) {
          margin-top: 8px;
        }
        span {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
  .action-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    .live-config-select-wrap {
      // margin-top: 100px;
      // margin-left: 100px;
      display: flex;
      align-items: center;
      width: 900px;
      margin: 0 auto;
      margin-top: 300px;
      span {
        font-size: 20px;
        color: #fff;
        text-align: left;
      }
      select {
        width: 500px;
        height: 30px;
      }
      button {
        // margin: 0 auto;
        width: 100px;
        height: 30px;
        // margin-top: 100px;
      }
    }
  }
}
