* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 13.888888888vw;
  text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  min-height: 100vh;
  font-family: Helvetica, "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

.animate__animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
